.rodape {
    display: flex;
    justify-content: center;
    background-color: var(--preto);
}

.rodape h2 {
    font-weight: 400;
    font-size: 18px;
    color: var(--branco);
}