.container {
    padding: 0 0 1em 0;
    margin-top: .5em;
    width: 282px;
    background-color: var(--cinza);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.capa {
    width: 100%;
}

.container h2,
.favoritar {
    padding: 0 1em;
}

.favoritar {
    width: 25px;
}

.link {
    text-decoration: none;
    color: var(--preto);
    text-align: left;
}